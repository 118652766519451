<template>
  <div>
    <vx-card actionable class="cardx">
      <h2 style="padding-top: 1%; padding-bottom: 1%">
        Enrollements Turn Around Time
      </h2>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            placeholder="Team"
            style="z-index: 1000; margin-top: 3%"
            class="w-full"
            v-model="selected_year"
            :options="years"
            label="name"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            placeholder="Months"
            style="z-index: 1000; margin-top: 3%"
            class="w-full"
            v-model="selected_month"
            :options="months"
            label="name"
          ></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button style="margin-top: 5%" color="dark" @click="getData"
            >Go</vs-button
          >
        </vs-col>
      </vs-row>
      <br />
      <div style="border: 1px solid rgba(214, 205, 205, 0.966); padding: 1%">
        <vs-row style="margin-bottom: 1%">
          <vs-col style="width: 25%"><b>ID</b></vs-col>
          <vs-col style="width: 25%"><b>Name</b></vs-col>
          <vs-col style="width: 25%"><b>Enrollement</b></vs-col>
          <vs-col style="width: 25%"><b>TAT</b></vs-col>
        </vs-row>
        <div v-if="get_selected_data != []">
          <div v-for="(tr, indextr) in get_selected_data" :key="indextr">
            <vs-row style="background:rgb(125, 104, 219); color:white">
              <vs-col style="width: 25%; padding: 1%">{{ tr.identity }}</vs-col>
              <vs-col style="width: 25%; padding: 1%">{{
                tr.person_name
              }}</vs-col>
              <vs-col style="width: 25%; padding: 1%">{{
                customFormatter(tr.enrollment_date)
              }}</vs-col>
              <vs-col style="width: 25%; padding: 1%">{{ tr.tat }} </vs-col>
            </vs-row>

            <turn-around-time-sources-card
              :sources_data="tr"
            ></turn-around-time-sources-card>
          </div>
        </div>
        <div v-if="get_selected_data == []" align="center" justify="center">NO DATA AVAILABLE</div>
        <!--dfdf-->
      </div>

      <!-- <vs-pagination style="padding-top: 1%;" :total="total_pages"  v-model="current_table_page"></vs-pagination> -->
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import moment from "moment";
import vSelect from "vue-select";
import TurnAroundTimeSourcesCard from "../components/pagesComponents/TurnAroundTimeSourcesCard.vue";
export default {
  components: {
    "v-select": vSelect,
    TurnAroundTimeSourcesCard,
  },
  data() {
    return {
      // total_pages: 1,
      // current_table_page: 1,
      get_selected_data: [],
      selected_year: 2011,
      selected_month: "January",
      selected_month_number: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [],
      tat_data: [],
    };
  },
  mounted() {
    this.getYears();
  },
  watch: {
    selected_month(val) {
      this.getMonthNUmber(val);
    },
    selected_year(val) {
      this.selected_year = val;
      this.getEnrollmentSourceReports();
    },
  },
  methods: {
    getYears(){
      let current_year =  new Date().getFullYear();
      console.log("current year", current_year);
      for (let i = 2011; i <= current_year; i++) {
        this.years.push(i);
        
      }
      console.log(this.years, "years list");
    },
    customFormatter(enrollment_date) {
      var timestamp = moment.unix(enrollment_date);
      return timestamp.format("DD-MMM-YYYY");
    },
    getMonthNUmber(month) {
      this.selected_month_number = String(
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].indexOf(month) + 1
      );
      this.selected_month_number = parseInt(this.selected_month_number);
      return this.selected_month_number;
    },
    getData() {
      if(this.selected_month_number == null){
        this.selected_month_number = this.getMonthNUmber(this.selected_month);
      }
      //  this.$vs.loading();
      this.get_selected_data = [];
      console.log(
        this.selected_year,
        this.selected_month_number,
        "selected year month out side for each"
      );
      for (let i = 0; i < this.tat_data.length; i++) {
        const element = this.tat_data[i];
        console.log(
          this.selected_year,
          this.selected_month_number,
          "selected year month in side for each"
        );
        if (
          this.selected_year === element.enrollment_year &&
          this.selected_month_number === element.enrollment_month
        ) {
          console.log(
            element.enrollment_year,
            element.enrollment_month,
            "inside if condition"
          );
          this.get_selected_data.push(element);
        }
      }
      // this.$vs.loading.close();
      console.log(this.get_selected_data, "%%%%%%%%%%%%%%%%%%");
      return this.get_selected_data;
    },
    getEnrollmentSourceReports() {
     
      let url = `${constants.SERVER_API}getEnrollmentSourceReports?file_name=${this.selected_year}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          
          console.log("year data", response);
          this.tat_data = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
.name-row {
  background: rgb(206, 192, 243);
  color: white;
  font-size: 20%;
  width: 100%;
}
.name-main {
  color: rgb(177, 177, 177);
  background: rgb(125, 104, 219);
  font-size: 20%;
}
</style>