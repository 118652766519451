<template>
  <div>
    <vs-table :data="sources_data.sources">
      <template slot="header"> </template>
      <template slot="thead">
        <vs-th> Date </vs-th>
        <vs-th> Source </vs-th>
        <vs-th> Source Details </vs-th>
        <vs-th> Type </vs-th>
        <vs-th> Table </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr style="margin-top:1%;" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].table">
            {{ customFormatter(tr.time) }}
          </vs-td>
          <vs-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </vs-td>
          <vs-td :data="data[indextr].details">
            {{ data[indextr].details }}
          </vs-td>
          <vs-td :data="data[indextr].net_enquiry_type">
            {{ data[indextr].net_enquiry_type }}
          </vs-td>
          <vs-td :data="data[indextr].table">
            {{ data[indextr].table }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["sources_data"],
  mounted() {
    console.log("####$%#$%#", this.sources_data);
  },
  methods: {
    customFormatter(enrollment_date) {
      var timestamp = moment.unix(enrollment_date);
      return timestamp.format("DD-MMM-YYYY");
    },
  },
};
</script>

<style>
</style>